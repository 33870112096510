<template>
  <v-container class="content-page">
    <title-header-action
      :hasActionPermission="canRegisterUser"
      btnLabel="Cadastrar Funcionário"
      :btnAction="goToAddUser"
    />
    <v-card class="table-page pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <!--filters -->
          <template v-if="showFilters">
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" md="3">
                <v-select
                  :items="Object.values(profileSelect)"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('roles', $event)"
                  color="secondary-color-1"
                  label="Perfil"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>

              <v-col cols="9" sm="8" md="3">
                <v-text-field
                  @input="setFilterDebounced('name', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar funcionário"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="3" sm="4" md="2">
                <report-options
                  :fileName="'Funcionários'"
                  :header="headerReport"
                  :body="users"
                />
              </v-col>
            </div>
            <v-divider></v-divider>
          </template>

          <template v-if="!users.length">
            <div class="pt-10">
              <data-not-found
                v-if="!filterPristine"
                title="Funcionário não encontrado."
                :imgSrc="require('@/assets/user.svg')"
              />

              <data-not-found
                v-if="filterPristine"
                title="Você ainda não cadastrou nenhum funcionário, deseja cadastrar?"
                btnLabel="Cadastrar o primeiro funcionário"
                :btnAction="goToAddUser"
                :imgSrc="require('@/assets/user.svg')"
              />
            </div>
          </template>

          <template v-if="users.length">
            <v-data-table
              :headers="headers"
              :items="users"
              :items-per-page="tableInfo.itemsPerPage"
              hide-default-footer
            >
              <template v-slot:[`item.fullName`]="{ item }">
                <div class="d-flex align-center">
                  <user-photo
                    class="mr-2"
                    v-model="item.photoHash"
                    size="sm"
                    border="thin"
                  />
                  <p class="ma-0">{{ item.fullName }}</p>
                </div>
              </template>
              <template v-slot:[`item.cpf`]="{ item }">
                {{ item.cpf | VMask(masks.cpf) }}
              </template>
              <template v-slot:[`item.situation`]="{ item }">
                <user-situation-chip :text="item.situation" />
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <user-menu-item
                  :user="item"
                  :canManageUser="canManageUser"
                  @updateData="updateData"
                />
              </template>
            </v-data-table>
          </template>
        </div>

        <div v-if="users.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
  import { mapGetters, mapActions } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";
  import UserMenuItem from "@/modules/account/user/views/components/UserMenuItem";
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";
  import UserSituationChip from "@/modules/account/user/views/components/UserSituationChip";

  import { commonProfiles, profiles } from "@/modules/core/enums/profiles.enum";

  export default {
    components: {
      DataNotFound,
      Pagination,
      ReportOptions,
      TitleHeaderAction,
      UserMenuItem,
      UserPhoto,
      UserSituationChip
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "fullName"
        },
        { text: "CPF", value: "cpf" },
        { text: "Perfil", value: "profile" },
        { text: "CRC", value: "crc" },
        { text: "Telefone", value: "phone" },
        { text: "Situação", value: "situation" },
        { value: "id", sortable: false }
      ],

      headerReport: [
        {
          header: "Nome",
          dataKey: "fullName"
        },
        { header: "CPF", dataKey: "cpf" },
        { header: "Perfil", dataKey: "profile" },
        { header: "CRC", dataKey: "crc" },
        { header: "Telefone", dataKey: "phone" },
        { header: "Situação", dataKey: "situation" }
      ],

      users: [],

      crcs: [
        {
          id: null,
          description: null
        }
      ]
    }),
    created() {
      this.initFilter("name", "");
      this.initFilter("roles", null);
      this.initFilter("crcId", null);
    },
    mounted() {
      this.fetchUsers();
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
    },

    computed: {
      ...mapGetters({
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin",
        userIsManagerOperator: "user/getUserIsManagerOperator"
      }),
      profileSelect() {
        const all = {
          All: {
            description: "Todos",
            id: ""
          }
        };

        if (this.userIsAdmin) {
          return { ...all, ...profiles };
        }

        return { ...all, ...commonProfiles };
      },
      showFilters() {
        if (this.users.length) {
          return true;
        }
        return !this.users.length && !this.filterPristine;
      },
      canRegisterUser() {
        const canRegister = [
          profiles.PRESIDENT.id,
          profiles.DIRECTOR.id,
          profiles.MANAGER_OPERATING.id
        ];

        return canRegister.includes(this.user.role.id);
      },
      canManageUser() {
        return this.userIsAdmin || this.userIsManagerOperator;
      },
      commonProfilesParam() {
        return Object.values(commonProfiles)
          .map(profile => profile.id)
          .join(",");
      }
    },
    methods: {
      ...mapActions("user", ["getUsers"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions(["toggleSnackbar"]),

      goToAddUser() {
        this.$router.push({ name: "AddUser" });
      },
      prepareRoles() {
        return this.userIsAdmin ? this.filters.roles : this.commonProfilesParam;
      },
      async fetchUsers() {
        try {
          const payload = this.getPayloadData();
          payload.roles = this.prepareRoles();
          const { data, meta } = await this.getUsers(payload);
          this.users = this.formatUsersData(data);
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatUsersData(users) {
        return users.map(user => {
          const userRole = user.userRoles[0];
          const phone = user.phones.length
            ? this.parsePhone(user.phones[0])
            : "não informado";

          return {
            id: user.id,
            fullName: `${user.firstName} ${user.surname}`,
            photoHash: user.photo?.hash ?? null,
            cpf: user.cpf ?? "não informado",
            profile: userRole?.role?.description ?? "não informado",
            crc: userRole?.crc?.description ?? "não informado",
            phone,
            situation: user.situationUser.description,
            email: user.email
          };
        });
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            ...data,
            {
              description: "Todos",
              id: ""
            }
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      updateData() {
        this.fetchUsers();
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/buttons";
  @include btn-primary();
</style>
